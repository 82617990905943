import React from 'react'
import './Projectview.css'
import { Link } from 'react-router-dom'

const ProjectView = (props) => {

  const Ismobile = props.isMobile



    const arry = [
        {
            img : 'https://img.freepik.com/free-photo/accountant-calculating-profit-with-financial-analysis-graphs_74855-4937.jpg?t=st=1718508880~exp=1718512480~hmac=5e04f49ad82a6845e640753275fef55b3c81e7e34ea34c6ccc7aa4e0632507df&w=2000',
            name : 'Banking Software',
            dis:"This software manages various aspects of banking operations, including customer accounts, transactions, loans, and investments.",
            path: '/Banking'
          },{
            img : 'https://img.freepik.com/free-photo/trading-finances-young-man-resting-couch-while-checking-stock-market-laptop_662251-2187.jpg?t=st=1718508914~exp=1718512514~hmac=b3268a7592a3420b0362bb413cdc4e0b6a0948c55ac1c015814985c6febbc57a&w=2000',
            name : 'Trading Algorithm',
            dis:"These algorithms automate the buying and selling of securities in the financial markets based on predefined rules and conditions.",
            path: '/Banking'
        },{
            img : 'https://img.freepik.com/free-photo/representations-user-experience-interface-design_23-2150038903.jpg?t=st=1718508970~exp=1718512570~hmac=9d2496377f1d6380a258294eb5e9574e4ec3669e9ea5145e882ba14c7106f3bd&w=1800',
            name : 'Mobile Application',
            dis:"These applications provide users with access to services and information on their mobile devices.",
            path: '/Banking'
        },{
            img : 'https://img.freepik.com/free-photo/ui-ux-representations-with-laptop_23-2150201871.jpg?t=st=1718509055~exp=1718512655~hmac=522a425cd3fbdab3a90a49fb41a698be513540f1739b2e565f1cc6a88d366865&w=2000',
            name : 'E-commerce  ',
            dis:"These services enable businesses to sell products and services online.",
            path: '/Banking'
        }
    ]


  return (
    <div>
        {/* <div style={{height:2,background:'#BEBEBE',margin:'70px 20%'}}></div> */}
        <h2 style={{textAlign:"center"}} data-aos="fade-right" className='heading'>
            OUR SERVICES
        </h2>
        {/* <h2 data-aos="fade-right" style={{fontWeight:400,marginLeft:'5%',fontSize:Ismobile?18:24}}>Transform your business with advanced technologies</h2> */}
      <div style={{display:Ismobile?'contents':'flex',justifyContent:'center'}}>
            {
            arry.map((item,index)=>(
              <div data-aos="fade-up" style={{textAlign:"center",margin:Ismobile?'25px 1vw':15,boxShadow:'0px 1px 5px grey',overflow:'hidden',width:Ismobile?'98vw':300}}>
               <Link to={item.path}><div  style={{overflow:'hidden',width:Ismobile?'98vw':300,height:Ismobile?200:150}}>
                    {/* <h3 style={{color:'black',position:'absolute',background:'white',padding:8,fontSize:14}}>{item.name}</h3> */}
                    <img className='zoom' style={{height:'100%',width:'100%',objectFit:'cover'}} src={item.img} alt='img'/>
                </div></Link>
                <h3>{item.name}</h3>
                <p style={{padding:5,fontSize:14}}>{item.dis}</p>
                </div> 
            ))
            }
      </div>
    </div>
  )
}

export default ProjectView
