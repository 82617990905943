import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testimonial.css';
const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "center",
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 940,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]

  };
  

function Testimonials() {

    const data=[
        {
            name:"Arvind Arora",
            image:"https://via.placeholder.com/150",
            testimonial:"From day one, Duniyape Technologies provided expert guidance. Their ongoing support and technical advice have been crucial to our success.",
            position:"Developer",
            company:"ABC technologies"         
        },
        {
            name:"Ravindra Singh",
            image:"https://via.placeholder.com/150",
            testimonial:"Their team handled our project with professionalism and expertise. They delivered ahead of schedule, and we’re thrilled with the outcome.",
            position:"Developer",
            company:"ABC technologies"         
        },
        {
            name:"Suresh Singhania",
            image:"https://via.placeholder.com/150",
            testimonial:"The proactive approach of Duniyape Technologies helped us stay ahead in a competitive market. Their team has been instrumental in our growth.",
            position:"Developer",
            company:"ABC technologies"         
        },
        {
            name:"Manjeet Kukreja",
            image:"https://via.placeholder.com/150",
            testimonial:"The service provided by Duniyape Technologies exceeded our expectations. The team was knowledgeable, quick, and always available to support us.",
            position:"Developer",
            company:"ABC technologies"         
        }
    ]

  return (
    <div  className="blogs" style={{width:"70%",margin:"0 auto"}}>
  
  <h2 style={{textAlign:'center',marginTop:'80px'}}></h2>
  <h2 data-aos="fade-right" className='heading'>
        TESTIMONIALS
    </h2>
  <div style={{textAlign:"center"}}>

      <Slider {...settings}>
{data.map(item=>(
    <div style={{padding:"50px"}}>
    <div class="testimonial">
     <div class="quote">
      <i class="fas fa-quote-left">
      </i>
     </div>
     <div class="stars">
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
      <i class="fas fa-star">
      </i>
     </div>

     <p style={{ 
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '1.5em', // Adjust line height as needed
}}>
    {item.testimonial}
     </p>
     <hr/>
     <div class="customer">
      <img alt="Customer profile picture" height="40" src="https://static.vecteezy.com/system/resources/previews/005/544/718/non_2x/profile-icon-design-free-vector.jpg" width="40"/>
      <div>
       <div class="name">
        {item.name}
       </div>
       <div class="role">
        {item.company}
       </div>
      </div>
     </div>
    </div>
    </div>
))}
      </Slider>
    </div>


    </div>
  )
}

export default Testimonials
