import React from 'react'
import './Btn.css'


const Services = () => {

  const arry = [
    {
      title:'Artificial Intelligence'
    },{
      title:'Cloud'
    },{
      title:'Enterprise Solutions'
    },{
      title:'Consulting'
    },{
      title:'Cybersecurity'
    },{
      title:'Data and Analytics'
    },{
      title:'Cognitive Business Operations'
    },{
      title:'IoT and Digital Engineering'
    }
  ]


  return (
    <div className='w100' style={{width:'70%',display:'flex',color:'white', margin:"auto"}}>
<div   style={{
        // width:'50vw',
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
        justifyContent:'space-around',

        padding:10
        }}>


        {
          arry.map((item,index)=>(
            <div style={{
              width:200,
              // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              // margin:'30px 20px'
            }} class="fromLeft hh1" data-aos="flip-left">
              <h3 style={{textAlign:"center"}}>{item.title}</h3>
              </div>
          ))
        }

      </div>
    </div>
  )
}

export default Services
