import './App.css';
import NavbarDasktop from './components/navbar/NavbarDasktop';
import Header from './components/header/Header';
import Services from './components/services/Services';
import { useEffect, useState } from 'react';
import ProjectView from './components/projectview/ProjectView';
import Footer from './components/footer/Footer';
import InquiryForm from './components/inquiry/InquiryForm';
import Testimonials from './components/testimonials/Testimonials';
import About from './components/about/About';


function App() {
  return (
    <Root/>
  );
}

export default App;


const Root=()=>{

  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [navht, setNavht] = useState(0)
  const navhandle = (data) => {
    setNavht(data);
    console.log(data)
};


const handleDataFromChild = (data) => {
  document.getElementById(data).scrollIntoView({ behavior: "smooth" });
};

// const handleLogout = () => {
//   setIsLoggedIn(false);
//   console.log("log out")
// };
 


  return (
<div>
<div>
<Header isMobile={isMobile}/>
</div>

<div>
  {/* <Middleimage isMobile={isMobile}/> */}
</div>
{/* <div id='About' style={{padding:"5% 0",background:'black',overflow:"hidden"}}>
  <About isMobile={isMobile}/>
</div> */}
<div id='service' style={{padding:"10px 0 4% 0"}}>
  <ProjectView isMobile={isMobile}/>
</div>

<div id='services' style={{background:'url(https://pegasustechnologies.com/wp-content/uploads/2022/02/content.jpg)',objectFit:"cover"}}>
<div style={{background:"#0000008c",padding:"5% 0"}}>
  <Services/>
</div>
</div>


<div style={{padding:'1% 0 2%'}} >
  <Testimonials />
</div>
<div style={{padding:"2% 0",background:"#1c1b1b0f"}}>
  <InquiryForm isMobile={isMobile}/>
</div>


<div id='contact' style={{background:'url(https://pegasustechnologies.com/wp-content/uploads/2022/02/content.jpg)',objectFit:"cover"}}>
<div style={{display:'flex',alignItems:"end"}}>
<Footer isMobile={isMobile} setx={navhandle} sety={handleDataFromChild}/>
</div>
</div>

<div>
<NavbarDasktop isMobile={isMobile} setx={handleDataFromChild} navht={navht}/>
</div>

</div>
  )
}
