import React from 'react'
import './Btn.css'
import img from './bg.png'

const Header = (props) => {

  const Ismobile = props.isMobile

  return (
    <div style={{width:Ismobile?'100%':'100vw',paddingTop:72,overflow:'hidden'}}>

      {Ismobile?
      // <img style={{
      //   marginLeft:-600,
      //   height:'90vh'
      // }} src={img} alt='img'/>
       <video autoPlay loop muted playsInline style={{height:'100vh',marginLeft:-680}}>
        <source src='images/1005.mp4' type='video/mp4'/>
      </video>
      :

      // <img style={{
      //   width:'100%',
      //   height:'90vh'
      // }} src={img} alt='img'/>}
     
<video autoPlay loop muted playsInline style={{width:'100vw',height:"90vh",objectFit:"cover"}}>
<source src='images/1005.mp4' type='video/mp4'/>
</video>}




<div style={{position:'absolute',top:72,width:'100vw',display:'flex',alignItems:'center',height:'90vh'}}>


      <div style={{marginLeft:Ismobile?30:100}} >
      <h1 data-aos="fade-right" style={{fontSize:Ismobile?40:70,color:'white',
        textShadow:'2px 1px black',
        margin:0,wordSpacing:4,letterSpacing:2}} className='oswald'>DUNIYAPE</h1>
         <h1 data-aos="fade-left" style={{fontSize:Ismobile?40:70,color:'white',
        textShadow:'2px 1px black',
        margin:0,wordSpacing:4,letterSpacing:2}} className='oswald'>TECHNOLOGIES</h1>
      <h2 data-aos="fade-right" style={{fontSize:Ismobile?18:24,color:'white',fontWeight:400,margin:'10px 0px 20px 0px',wordSpacing:4,letterSpacing:1.1,width:'90%'}}>Software Consulting and Development</h2>
      <div style={{width:'40%',height:1,background:'white'}}></div>
      <p data-aos="fade-left" style={{fontSize:Ismobile?12:14,color:'white',fontWeight:300,margin:'20px 0px 20px 0px',width:'40%',wordSpacing:4,letterSpacing:2}}>Duniyape Technologies Forward-thinking tech company driving business success through innovative solutions, collaboration, and excellence. Empowering organizations to thrive in a digital world.</p>
      <div>
  
      </div>
      
      </div>
      
      </div>
      



    </div>
  )
}

export default Header
