import React from 'react'
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import NavbarDasktop from '../../components/navbar/NavbarDasktop';
import './banking.css'


const Banking = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
    const [show, setshow] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [navht, setNavht] = useState(0)
  const navhandle = (data) => {
    setNavht(data);
    console.log(data)
};

const handleDataFromChild = (data) => {
  document.getElementById(data).scrollIntoView({ behavior: "smooth" });
};
const handleshow = (e) => {
  const element = document.getElementById(e);
  if (element.classList.contains("active")) {
    element.classList.remove("active");
  } else {
    element.classList.add("active");
  }
};



  return (
    <div>
        <div style={{height:72}}></div>
        <div>
<NavbarDasktop isMobile={isMobile} setx={handleDataFromChild} navht={navht}/>
</div>
<div style={{width:"70%",margin:"auto",display:"flex"}}>
<div className="bcontainer">
      <div className="header">
        <div className="title">Core Banking Solution</div>
        
      </div>
      <div className="image-container">
        <img src="https://www.techfunnel.com/wp-content/uploads/2019/07/12-Digital-Banking-Challenges-and-Opportunities-For-the-Banking-Industry-1.png" alt="Accountant using E Invoice Software" />
      </div>
      <div className="content">
        <div className="left-content">
          <p>
            Duniyape is designed to simplify financial activities of Co-Operative Bank, which gives you
            complete control over accounting, inventory and statutory processes.
          </p>
          <p>
            Duniyape is recognized for feature rich business solution with English and regional language support.
            Continuous enhancement of the software over the years has made it well matured and stabilized
          </p>
          <p style={{background:"#80808038",padding:30}}>
            Core Banking Solution enables the banks to provide many additional delivery channels to their customers.
            The solution can be installed on-premise or used on the cloud.
          </p>
          <div className="features">
           Features And Benefits
          </div>
        </div>
     
      </div>
    <div style={{width:'100%'}}>
    <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Supports multiple channels like Branches, ATM / POS, Mobile</p>
    <p><i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Faster Decision Making</p>
    <p><i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>New Revenue Streams</p>
    <p><i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Reduced operational & support cost</p>
    <p><i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Cut expenses without sacrificing quality</p>
    <p><i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Automate your business, so you can leave for days,</p>
    </div>
    <div id="Features" className='Benefits' style={{width:'100%'}}>
      <div  className='box'>
        <h3 onClick={()=>handleshow("master")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/> Master Entry</h3>
        <div id="master" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Customer Master , Permanent /Present address maintenance</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Photo and Signature scanning and storage system</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Customer Management , SHG Promoter/SHG Circle</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>A/C Head Creation, A/C Group Creation and Non A/C Head Creation</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Share Master, Deposit Master, Loan Master</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Investment Master, Borrowing Master, Other Subsidiary Master</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Employee / Staff master, Asset Management, Rent Management</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Printing and Stationary Management / Inward / outward / Stockt</p>
      </div>
      </div>
      <div className='box'>
        <h3 onClick={()=>handleshow("transaction")} className='subheadings' ><i className='fa-solid fa-plus' style={{marginRight:5}}/>Transaction Entry</h3>
        <div id="transaction" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
        <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Receipt/Payment/Adjustment Voucher Entry</p>
        <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>SB/CD Interest, Periodically FD Interest Transfer</p>
        <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Branch Monthly R&D Entry, Memorandum Voucher Entry</p>
        <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Master entry authentication, Voucher Authentication</p>
        <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Transaction from Pigmy Machine, Branch Reconciliation</p>
      </div>
      </div>
      
      <div id="Modules" className='box'>
        <h3 onClick={()=>handleshow("deposit")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Deposits /Investment Module</h3>
        <div id="deposit" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Deposit Master , Investment Master, Deposit Holder Balance List</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Deposit Received Register, Supervisor Wise Balance</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Deposit Maturity List, Deposit Individual Ledger</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Interest Payable and Paid (For Any Date), Interest Wise Deposit List</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Cheque Book Issue /Stop Payment / Cheque book stock statement</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Investment Ledger, SB A/c Passbook Printing</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Interest rate schedule , Deposit Certificate issue</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Pigmy Depositors Individual Ledger, Pigmy Collection Monthly Statement</p>
     </div>
     </div>
      <div className='box'>
        <h3 onClick={()=>handleshow("share")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Share Module</h3>
        <div id="share" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Share Master, Shareholders Balance List , Dividend Payable List</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Dividend Voucher, Members List And Address List (Conditions)</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Area Wise Member List And Address List ,Nominee List</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Members Group wise/Cast wise Report , Members Identity Card</p>
        <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Liabilities Register / Member Status (Including Surety Details) Share Certificate printing</p>
     </div>
     </div>
     <div  className='box'>
  <h3 onClick={()=>handleshow("furniture")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Furniture & Fixture Module</h3>
  <div id="furniture" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Furniture and fixture class, Furniture and fixture sub a/c, Assigning distinct number</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Furniture purchase, dispose and transfer, Furniture Location change</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Depreciation calculation</p>
</div>
</div>
<div className='box'>
  <h3 onClick={()=>handleshow("optional")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Optional Modules</h3>
  <div id="optional" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Vaidyanathan's Related Report</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Coverage Of Membership – Annexure 1</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>DCB Statement (VI) & Period-Wise Classification of Overdue (VII)</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Asset Classification and Provisioning Requirement</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Detail Of Deposit Mobilized –XII & Growth In Share Capital (XIII)</p>
</div>
</div>
<div className='box'>
  <h3 onClick={()=>handleshow("inventory")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Inventory Module</h3>
  <div id="inventory" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Item Master, Product, Make, VAT group master</p>
  <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Sales, Purchase, Transfer, Sales Return, Purchase Return, Stock Journal</p>
  <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Debit Note, Credit Note, Stock Verification Entry, Price List Entry</p>
  <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Inventory Status-, Low Stock, High Stock, and Age wise Stock</p>
  <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Fast Moving, Slow/ Non Moving Goods, Stock Statement, Stock Verification Reports</p>
  <p> <i class="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Stock ledger, Stock Register, Item wise, Place wise, Supplier wise, Branch wise, Section wise, Party wise, Sales & Purchase ,Job work Stock Ledge</p>
  </div>
</div>
     
<div className='box'>
  <h3 onClick={()=>handleshow("borrowing")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Loan/Borrowing Module</h3>
  <div id="borrowing" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Loan master entry, Loan borrowing master entry, Loan Balance List</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Loan Issue Register, Loan Group wise Balance List</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Loan borrowers Address List, Loan borrowers Count</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Loan Borrowing List, Notice Issues, Members Loan Over Due List</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Borrowings Over Due List, Defaulter List</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Loan Status (Bal., Od,. Int. Due, Next Installment dt, Other Dues)</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Transaction Related Reports, Demand Collection Balance Statements</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Jewel Issue Register, Jewel Balance Register,Jewel Ledger</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Loan Ledger,Loan Pass Book, Interest Wise List</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Gold Stock List,Gold Searching, Product Loan Management</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>CCL, MKCC etc Renewal, CCL, MKCC etc Renewal Draw Limit Renewal</p>
</div>
</div>
<div className='box'>
  <h3 onClick={()=>handleshow("cheque")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Cheque Collection Module</h3>
  <div id="cheque" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Bank Master, Branch Master, Holiday Master</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Clearing House Master, Clearing Number Master</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Inward OCC, Inward Clearing Out, Inward Our Branch Cheque</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>OCC Cheque, Clearing Out Cheque, Clearing In Cheque, Day end Voucher</p>
</div>
</div>

<div className='box'>
  <h3 onClick={()=>handleshow("bills")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Bills Collection Module</h3>
  <div id="bills" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Inward Bills For Collections, Inward Bills Closure, BC Related Voucher, Remittance</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Outward Bills Collection Master, Outward Bills Conversion, Outward Bills Closure</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Entry and maintenance of DD/PO Payable & Receivable</p>
</div>
</div>

<div  className='box'>
  <h3 onClick={()=>handleshow("pay")}  className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Pay Demand Module</h3>
  <div id="pay" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Organization Master, Pay Demand Report Configuration</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Demand Generation, Automatic Demand Voucher Adjusted System</p>
</div>
</div>

<div className='box'>
  <h3 onClick={()=>handleshow("locker")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Locker Management Module</h3>
  <div id="locker" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Locker Rent Master, Locker Rent Amount Setting.</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Locker Status, Locker Due List, Locker Allotment Status</p>
</div>
</div>

<div className='box'>
  <h3 onClick={()=>handleshow("loan")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Loan Module</h3>
  <div id="loan" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Borrowings Over Due List, Defaulter List</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Loan Status (Bal., Od,. Int. Due, Next Installment dt, Other Dues)</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Transaction Related Reports, Demand Collection Balance Statements</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Jewel Issue Register, Jewel Balance Register, Jewel Ledger</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Loan Ledger, Loan Pass Book, Interest Wise List</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Gold Stock List, Gold Searching, Product Loan Management</p>
  <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>CCL, MKCC etc Renewal, CCL, MKCC etc Renewal Draw Limit Renewal</p>
</div>
</div>
<div id="Benefits"className='box'>
      <h3 onClick={()=>handleshow("payroll")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Payroll</h3>
      <div id="payroll" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
      <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Employee / Staff Master, Maintenance fixed deduction</p>
      <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Maintenance of Monthly salary details of all employees for a specified period</p>
      <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Salary certificates, Employee's statement</p>
    </div>
    </div>
    <div className='box'>
      <h3 onClick={()=>handleshow("financial")} className='subheadings'><i className='fa-solid fa-plus' style={{marginRight:5}}/>Financial Reports</h3>
      <div id="financial" style={{height:0,overflow:"hidden",transition: 'max-height 2s ease-in-out'}} >
      <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Cash Chitta, Daybook, General Ledger/Month-wise/Day-wise</p>
      <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>R & D (Receipt and Payments)</p>
      <p> <i className="fa-solid fa-check" style={{color:"#74C0FC",marginRight:8}}></i>Trial Balance, Trading A/C, Profit and Loss A/C, Balance sheet</p>
    </div>
    </div>





    </div>
     
    </div>


  <div className='Right-side' style={{width:"20%"}}>
          <div className="company-brochure">
          <a href="#">Company Brochure</a>
        </div>
        <div className="right-content">
          <div className="how-can-we-help-you">
            <h3>how can we help you?</h3>
            <p>
              Contact us at our Bhopal office or submit a business inquiry online.
            </p>
            <a href="#">contacts</a>
          </div>
          <div className="smartfincore">
            <p>
              SmartFincore with its parameterized platform fulfilled our needs, manage our IT
              operations well.
            </p>
            <div className="person">
              <img src="https://i.imgur.com/0774W13.png" alt="G R Prasad" />
              <div>
              <p>
                RAJA BHARADWAJ
              </p>
              <p style={{fontSize:12}}>
                Director
              </p>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>



     <div id='contact' style={{background:'url(https://pegasustechnologies.com/wp-content/uploads/2022/02/content.jpg)',objectFit:"cover"}}>
<div style={{display:'flex',alignItems:"end"}}>
<Footer isMobile={isMobile} setx={navhandle} sety={handleDataFromChild}/>
</div>
</div>
 
    </div>
  )
}

export default Banking
